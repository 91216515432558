.dialect {
    --dt-accent-brand: #737aff;
    --dt-accent-success: #737aff;
    --dt-bg-primary: "#0A0F38";
    --dt-input-checked: #737aff;
    --dt-text-accent: #737aff;
}
.dialect[data-theme="dark"] {
    --dt-accent-brand: #384bef;
    --dt-accent-success: #384bef;
    --dt-bg-primary: "#FFFFFF";
    --dt-bg-secondary: #151619;
    --dt-bg-tertiary: #2a2a2b;
    --dt-input-checked: #384bef;
    --dt-text-accent: #384bef;
}

.dialect {
    min-height: 300px;
}

.dialect .dt-font-semibold {
    font-weight: 400;
}

.dialect .dt-font-bold {
    font-weight: 500;
}
